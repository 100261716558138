
:root {
    --lightgray: #CCCED7;
    --font: 'Inter', sans-serif;
    --blue: #3743DE;
    --lightblue: #5863ED;
    --regular: 400;
    --medium: 500;
    --semi-bold: 00;
    --bold: 700;
    --nav-spacing: 30px;
    --main-dark-background: #14161F;
    --secondary-dark-background: #1C1C1C;
    --container-dark-background: #191B25;
}
html {
    background-color: var(--main-dark-background);
    scroll-behavior: smooth;
}
a {
    text-decoration: none;
}
.intro-text {
    font-family: var(--font);
    font-weight: var(--bold);
    font-size: 160px;
    color: white;
}
.heading-text {
    font-family: var(--font);
    font-weight: var(--bold);
    font-size: 75px;
    color: white;
}
.subheading-text {
    font-family: var(--font);
    font-weight: var(--bold);
    font-size: 48px;
    color: white;
}
.small-subheading-text {
    font-family: var(--font);
    font-weight: var(--bold);
    font-size: 24px;
    color: white;
}
.small-description-text {
    font-family: var(--font);
    font-weight: var(--medium);
    font-size: 16px;
    line-height: 40px;
    color: white;
}
.smaller-description-text {
    font-family: var(--font);
    font-weight: var(--medium);
    font-size: 12px;
    line-height: 40px;
    color: white;
}
.description-text {
    font-family: var(--font);
    font-weight: var(--medium);
    font-size: 20px;
    line-height: 35px;
    color: var(--lightgray);
}
.general-container {
    background-color: var(--container-dark-background);
    border-radius: 20px;
    border: solid var(--lightgray) 1px;
}
.construction {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.mobile-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    text-align: center;
}
.wrench {
    height: 200px;
}