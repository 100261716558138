@import "../../App.css";
.navbar-container {
    position: fixed;
    width: 100%;
    background-color:#14161F;
    z-index: 9;
}

.logo {
    width:  90px;
    padding-right: 60px;
}
.logo:hover {
    cursor: pointer;
}

.menu-icon {
    width: 30px;
    margin-right: 30px;
}
.menu-icon:hover {
    cursor: pointer;
}
.menu {
    position: absolute;
    top: 0px;
    right: -180px;
    width: 180px;
    height: 100vh;
    background-color: #1D1F28;
    transition: right 0.5s;
}
.menu-active {
    right: 0px;
}
.menu-button {
    color: var(--lightgray);
    font-family: var(--font);
    font-weight: var(--semi-bold);
    padding: 20px;
    border-top-style: solid;
    border-bottom-style: solid;
    border-width: 1px;
    border-color: #262832;
}
.menu-button:hover {
    background-color: #262832;
}
.navbar-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: var(--nav-spacing);
    padding-right: var(--nav-spacing);
}
.navbar-main-button {
    padding-right: 10px;
}
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.navbar-button {
    color: var(--lightgray);
    font-family: var(--font);
    font-weight: var(--semi-bold);
    margin-left: var(--nav-spacing);
    margin-right: var(--nav-spacing);
}
.navbar-button:hover {
    color: white;
    cursor: pointer;
}