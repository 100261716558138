@import "../../App.css";
.experience {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 200px;
    padding-bottom: 250px;
    overflow-x: hidden;
}
.education {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
}
.education-mobile {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
    width: 100%;
}
.education-text-mobile {
}
.education-text {
    margin-left: 50px;
    width: 800px;
}
.ut-logo {
    width: 150px;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 10px;
    padding-right: 10px;
}
.experience-logo {
    width: 100px;
    padding: 20px;
}
.sub-experience-logo {
    width: 100px;
    padding: 20px;
}
.experience-element {
    display: flex;
    align-items: center;
    width: 800px;
    margin: 50px;
}
.experience-element-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 50px;
    margin-top: 50px;
    margin-left: 10px;
    margin-right: 10px;
}
.sub-experience-element {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 600px;
    margin-left: 200px;
}
.experience-timeline {
    display: flex;
    align-items: center;
}
.timeline-subline {
    height: 170px;
    width: 10px;
    background-color: var(--blue);
}
.timeline-circle{
    height: 20px;
    width: 20px;
    border-radius: 10px;;
    background-color: var(--blue);
}
.timeline {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.experience-con {
    display: flex;
    align-items: center;
}
.mobile-row {
    display: flex;
    align-items: center;
}