@import "../../App.css";
.introduction {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
}
.intro-below {
  margin-top: -40px;
}
.h1-test {
    font-size: 3rem;
    font-weight: 100;
    line-height: 1.5;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    width: 700px;
  }
  
.span-test {
    font-size: 40px;
    margin-left: 0px;
}
  
.message {
    background-color: var(--blue);
    display: block;
    font-weight: 900;
    overflow: hidden;
    position: absolute;
    padding-left: 0.5rem;
    animation: openclose 8s ease-in-out infinite;
}
canvas {
    display: block;
    z-index: 1;
    padding-top: 150px;
}
#image {
  display: none;
}