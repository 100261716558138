@import "../App.css";

.flipboard {
    display: flex;
    justify-content: center;
    height: 900px;
    /*border: white solid;*/
}
.blur {
    position: absolute;
    height: 100vh;
    width: 100vw;
    max-width: 100%;
    background-color: rgba(0,0,0, 0.4);
    backdrop-filter: blur(5px);
    z-index: 9991;
    display: flex;
    justify-content: center;
}
.check {
    width: 100px;
}
.info {
    margin-top: 200px;
    color:white;
    z-index: 9992;
    background-color: #262832;
    padding: 50px;
    width: 680px;
    height: 280px;
    border-radius: 20px;
    text-align: center;
}
.flipboard-display {
    position: relative;
    margin-top: 100px;
    height: 720px;
    width: 1300px;
}

.flip-square {
    position: absolute;
    width: 60px;
    height: 40px;
    background-image: url("../images/symbol_sprite_sheet.png");
    background-size: cover;
}

.flipboard-hide-row {
    z-index: 999;
    background-color: #202020;
    height: 80px;
    position: relative;
}
@media only screen and (max-width: 1300px) {
    .flipboard-con {
        display: none;
    }
}
.flipboard-background {
    background-color: #202020;
    height: 700px;
    width: 1300px;
    position: absolute;
    top: 0px;
}
.flipboard-navbar {
    z-index: 9999;
}
.flipboard-input {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
    background-color: #262832;
    height: 40px;
    width: 700px;
    color: var(--lightgray);
    padding-left: 20px;
    border-radius: 20px;
    font-family: var(--font);
    font-weight: var(--medium);
}
.flipboard-input:focus {
    outline: none;
}
.flipboard-blog-title {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.flipboard-blog {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.blog-element {
    width: 1200px;
}
.flipboard-refresh {
    position: absolute;
    top: 830px;
    margin-left: 1180px;
}
.flipboard-heading {
    padding-top: 30px;
    padding-bottom: 30px;
}
.flip-code {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}
.code-border {
    border-radius: 20px;
}