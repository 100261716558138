@import "../../App.css";
.bottom-navbar {
    background-color: var(--secondary-dark-background);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.bottom-navbar-element {
    padding: 25px;
}
.more-info {
    display: flex;
    align-items: center;
}
.arrow {
    width: 36px;
}
.contact-info {
    line-height: 2
}