@import "../App.css";

.awesome {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
}

.awesome-image {
    width: 900px;
}
.awesome-title {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.awesome-blog-component {
    width: 1200px;
}
.awesome-chapter {
    padding-top: 80px;
}
.awesome-code {
    display: flex;
    justify-content: center;
}
.awesome-video {
    padding-top: 20px;
    padding-bottom: 40px;
}
.code-image {
    border-radius: 20px;
}
.last {
    margin-bottom: 100px;
}