@import "../../App.css";

.projects {
    /*border: solid white;*/
    padding-top: 100px;
}
.projects-title {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.project {
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
}
.project-container {
    padding: 40px;
}
.project-info-left {
    position: absolute;
    padding: 20px;
    left: -100px;
    top: 120px;
    width: 400px;
}
.project-info-right {
    position: absolute;
    padding: 20px;
    right: -100px;
    bottom: 20px;
    width: 400px;
}
.project-button {
    float: right;
}
.navbar-buttons {
    display: flex;
    height: 75px;
}
.project-navbar {
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
}
.project-description {
    width: 1000px;
}
.project-description-mobile {
    width: 90%;
}
.project-image {
    width: 700px;
}


.project-image-mobile {
    width: 100%;
}
.project-container-mobile {
    padding: 20px;
    margin-left: 10px;
    margin-right: 10px;
}
.project-info-mobile {
    padding: 20px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
}